// TokenInterceptorSetup.js
import React, { useEffect } from 'react';
import { apiService } from './services/apiService';
import useAccessToken from './hooks/useAccessToken';

const TokenInterceptorSetup = ({ children }) => {
    const fetchAccessToken = useAccessToken();

    useEffect(() => {
        apiService.setupInterceptors(fetchAccessToken);
    }, [fetchAccessToken]);

    return <>{children}</>;
};

export default TokenInterceptorSetup;
