const cacheTTL = 5000; // 5 секунд
const requests = {};

export const fetchWithCache = async (apiCall, cacheKey) => {
    const now = Date.now();

    const cachedData = sessionStorage.getItem(cacheKey);
    const cachedTime = sessionStorage.getItem(`${cacheKey}FetchTime`);
    const cachedTimeNumber = cachedTime ? Number(cachedTime) : null;

    if (cachedData && cachedTimeNumber && now - cachedTimeNumber < cacheTTL) {
        return JSON.parse(cachedData);
    }

    if (requests[cacheKey]) {
        return await requests[cacheKey];
    }

    const requestPromise = (async () => {
        const response = await apiCall();
        const data = response.data;
        sessionStorage.setItem(cacheKey, JSON.stringify(data));
        sessionStorage.setItem(`${cacheKey}FetchTime`, now.toString());
        return data;
    })();

    requests[cacheKey] = requestPromise;

    try {
        const data = await requestPromise;
        delete requests[cacheKey];
        return data;
    } catch (error) {
        delete requests[cacheKey];
        throw error;
    }
};
