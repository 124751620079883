import Button from "react-bootstrap/Button";
import {Form, FormControl, InputGroup} from "react-bootstrap";
import {Search} from "react-bootstrap-icons";

const SearchBar = () => {
    return (
        <Form className="d-flex" role={"search"}>
            <InputGroup>
                <FormControl name="search" className="input-group-text" type="search" placeholder="Search..." aria-label="Search"/>
                <Button className="input-group-text" type='button' variant={"primary"}>
                    <Search/>
                </Button>
            </InputGroup>
        </Form>
    );
}

export default SearchBar;