// index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

    apiKey: "AIzaSyDlwXK-MBRC5agxeU72kl879RyM-vc6aJM",

    authDomain: "hdoc-6f3b9.firebaseapp.com",

    projectId: "hdoc-6f3b9",

    storageBucket: "hdoc-6f3b9.appspot.com",

    messagingSenderId: "385602881663",

    appId: "1:385602881663:web:0830599e68c495cad6174a",

    measurementId: "G-K5N68DE5B6"

};


// Initialize Firebase

const app = initializeApp(firebaseConfig);

getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App/>
    </React.StrictMode>
);

reportWebVitals();
