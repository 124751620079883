import './Footer.scss';

const Footer = () => {
    return (
        <footer className="bg-light text-center">
            <div className="text-center p-3">
                © {new Date().getFullYear()} Copyright: <a className="text-dark" href="/public">HDoc UI</a>
            </div>
            <div className="version">v {process.env.REACT_APP_VERSION}</div>
        </footer>
    );
}

export default Footer;