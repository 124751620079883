import React from 'react';
import './TablePlaceholder.module.scss';

const TablePlaceholder = () => {
    return (
        <tr>
            <td><span className="placeholder col-1"></span></td>
            <td>
                <a href="/" tabIndex="-1" className="btn btn-warning disabled placeholder col-1 me-2">&nbsp;</a>
                <a href="/" tabIndex="-1" className="btn btn-danger disabled placeholder col-1">&nbsp;</a>
            </td>
        </tr>
    );
};

export default TablePlaceholder;
