import { Navbar } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import SearchBar from '../SearchBar';
import { Link } from 'react-router-dom';
import UserHeader from "./UserHeader";

const Header = () => {
    return (
        <header>
            <Navbar variant={'light'} expand={'lg'} sticky={'top'}>
                <Container>
                    <Link to="/" className="navbar-brand">HDoc UI</Link>
                    <SearchBar />
                    <UserHeader/>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
