import {UserScope} from "@logto/react";

const oidcConfig = {
    endpoint: `${process.env.REACT_APP_AUTH_URL}`,
    appId: `${process.env.REACT_APP_AUTH_ID}`,
    resources: [`${process.env.REACT_APP_API_URL}`],
    scopes: [
        UserScope.Email,
        UserScope.Identities
    ]
};
export default oidcConfig;