import React, { useCallback, useRef, useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { X } from "react-bootstrap-icons";
import { useDropzone } from 'react-dropzone';
import { useDocumentContext } from '../../contexts/DocumentContext';
import { useDocumentApi } from "../../hooks/useDocumentApi";
import { toast } from "react-toastify";

const DocumentModal = ({ show, onHide }) => {
    const { documentTypes, loading } = useDocumentContext();
    const { addDocument, uploadDocument } = useDocumentApi();
    const [formData, setFormData] = useState({
        documentName: '',
        documentType: '',
        expiryDate: '',
        isExpiryDateApplicable: true,
        files: []
    });
    const fileInputRef = useRef();

    const handleAddMoreFiles = () => {
        fileInputRef.current.click();
    };

    const handleFileSelect = (e) => {
        const selectedFiles = Array.from(e.target.files).map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setFormData(formData => ({
            ...formData,
            files: [...formData.files, ...selectedFiles]
        }));
    };

    const onDrop = useCallback(acceptedFiles => {
        const filesWithPreview = acceptedFiles.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setFormData(formData => ({
            ...formData,
            files: [...formData.files, ...filesWithPreview]
        }));
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleRemoveFileInput = (index) => {
        setFormData(formData => ({
            ...formData,
            files: formData.files.filter((_, fileIndex) => fileIndex !== index)
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formattedExpiryDate = formData.expiryDate ? new Date(formData.expiryDate).toISOString() : null;

        const documentData = {
            title: formData.documentName,
            DocumentTypeID: formData.documentType,
            expiryDate: formattedExpiryDate,
        };

        try {
            const response = await addDocument(documentData);
            const createdDocument = response.data;

            if (formData.files.length > 0) {
                for (const file of formData.files) {
                    if (file) {
                        const uploadFormData = new FormData();
                        uploadFormData.append('file', file.file);

                        try {
                            await uploadDocument(createdDocument.id, uploadFormData);
                        } catch (uploadError) {
                            toast.error('Error uploading document: ' + uploadError.toString());
                            console.error('Error uploading file:', uploadError);
                        }
                    }
                }
            }
            toast.success('Document created successfully!');
            clearForm();
            onHide();
        } catch (error) {
            toast.error('Error uploading document!');
            console.error('Error while document creation or file upload:', error);
        }
    };

    const handleCheckboxChange = () => {
        setFormData({ ...formData, isExpiryDateApplicable: !formData.isExpiryDateApplicable });
    };

    const clearForm = () => {
        formData.files.forEach(item => URL.revokeObjectURL(item.preview));
        setFormData({
            documentName: '',
            documentType: '',
            expiryDate: '',
            isExpiryDateApplicable: true,
            files: []
        });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Add Document</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit} autoComplete="off">
                    <Form.Group controlId="documentName">
                        <Form.Label>Document Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="documentName"
                            placeholder="Enter document name"
                            value={formData.documentName}
                            onChange={handleInputChange} />
                    </Form.Group>

                    <Form.Group controlId="documentType">
                        <Form.Label>Document Type</Form.Label>
                        <Form.Control
                            as="select"
                            name="documentType"
                            value={formData.documentType}
                            onChange={handleInputChange}
                        >
                            <option value="">Choose...</option>
                            {documentTypes && documentTypes.map((type) => (
                                <option key={type.id} value={type.id}>{type.name}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="expiryDate">
                        <Form.Label>Expiry Date</Form.Label>
                        <Form.Control
                            type="date"
                            name="expiryDate"
                            disabled={!formData.isExpiryDateApplicable}
                            value={formData.expiryDate}
                            onChange={handleInputChange}
                        />
                    </Form.Group>

                    <Form.Group controlId="expiryDateCheckbox">
                        <Form.Check
                            type="checkbox"
                            label="No expiration date"
                            onChange={handleCheckboxChange}
                        />
                    </Form.Group>

                    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
                        <input {...getInputProps()} />
                        {
                            isDragActive ?
                                <p>Drop the files here ...</p> :
                                <p>Drag 'n' drop some files here, or click to select files</p>
                        }
                    </div>
                    {formData.files.map((item, index) => (
                        <Form.Group key={index} controlId={`documentFile-${index}`} className="d-flex align-items-center">
                            {item.file.type.startsWith('image/') && (
                                <img src={item.preview} alt="preview" style={{ width: '100px', marginRight: '10px' }} />
                            )}
                            <Form.Label className="me-2">{item.file.name}</Form.Label>
                            <Button variant="danger" onClick={() => handleRemoveFileInput(index)}>
                                <X size={20} />
                            </Button>
                        </Form.Group>
                    ))}
                    <Form.Group controlId="addMoreFiles" className="mt-3">
                        <input
                            ref={fileInputRef}
                            type="file"
                            multiple
                            onChange={handleFileSelect}
                            style={{ display: 'none' }}
                        />
                        <Button onClick={handleAddMoreFiles}>Add More Files</Button>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Close</Button>
                <Button variant="primary" onClick={handleSubmit}>Save Changes</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default DocumentModal;
