import { useState, useEffect } from 'react';
import { apiService } from "../services/apiService";
import { fetchWithCache } from '../utils/cacheUtils';

export const useDocumentApi = () => {
    const [documentTypes, setDocumentTypes] = useState([]);

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            try {
                const data = await fetchWithCache(apiService.getDocumentTypes, 'documentTypes');
                setDocumentTypes(data);
            } catch (error) {
                console.error('Failed to fetch document types:', error);
            }
        };

        fetchDocumentTypes();
    }, []);

    const addDocument = async (documentData) => {
        try {
            return await apiService.addDocument(documentData);
        } catch (error) {
            console.error('Error adding document:', error);
            throw error;
        }
    };

    const uploadDocument = async (documentId, file) => {
        try {
            return await apiService.uploadDocument(documentId, file);
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    };

    return { documentTypes, addDocument, uploadDocument };
};
