// useAccessToken.js
import { useLogto } from '@logto/react';

const useAccessToken = () => {
    const { getAccessToken } = useLogto();

    const fetchAccessToken = async () => {
        const res = `${process.env.REACT_APP_API_URL}`;
        console.log('TOKEN FOR:', res);
        return await getAccessToken(res);
    };

    return fetchAccessToken;
};

export default useAccessToken;
