import ModB from "./ModB";
import {Col, Row, Card} from "react-bootstrap";
import Document from "../document/document";
import Button from "react-bootstrap/Button";
import {FileEarmarkPlus, Tags} from 'react-bootstrap-icons'
import './ModRecent.scss'
import DocumentModal from "../document/DocumentModal";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

const ModRecent = () => {
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleManageCategories = () => navigate('/doc/type');
    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    return (<>
            <ModB className="mod-recent" title="Documents">
                <Row className="mod-recent-header">
                    <Col className="mod-recent-header-list">
                        <Card>
                            <Card.Body>
                                <Card.Title>Recent</Card.Title>
                                <Document path="../Personal" title="Pasport" expDate={new Date()}></Document>
                                <Document path="../Medical" title="Blood pressure" expDate={new Date()}></Document>
                                <Document path="../Tickets" title="IMAX Lord of the ri..." expDate={new Date()}></Document>
                                <Card.Link href='#'>View all</Card.Link>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col className="mod-recent-header-buttons">
                        <Button size={"lg"} onClick={handleShowModal}><FileEarmarkPlus size={30}/> Add Document</Button>
                        <Button size={"lg"} onClick={handleManageCategories}><Tags size={30}/> Manage categories</Button>
                    </Col>
                </Row>
            </ModB>
            <DocumentModal show={showModal} onHide={handleCloseModal} />
        </>
    );
}

export default ModRecent;