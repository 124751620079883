import React, { useState } from 'react';
import { Button, Col, Container, Form, Row, Table, Modal } from 'react-bootstrap';
import { useDocumentContext } from '../../contexts/DocumentContext';
import { ArrowClockwise, Pencil, Trash } from "react-bootstrap-icons";
import TablePlaceholder from "../TablePlaceholder/TablePlaceholder";
import { toast } from 'react-toastify';

const DocumentTypeManager = () => {
    const [newTypeName, setNewTypeName] = useState('');
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [editingType, setEditingType] = useState(null);
    const [editingTypeName, setEditingTypeName] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteTypeId, setDeleteTypeId] = useState(null);
    const [deleteTypeName, setDeleteTypeName] = useState('');
    const [deleteConfirmed, setDeleteConfirmed] = useState(false);

    const { documentTypes, addDocumentType, editDocumentType, deleteDocumentType, refreshDocumentTypes, loading } = useDocumentContext();

    const handleAddDocumentType = () => {
        if (newTypeName) {
            addDocumentType({ name: newTypeName })
                .then(() => {
                    setNewTypeName('');
                    handleRefreshDocumentTypes();
                    toast.success('Document type added successfully');
                })
                .catch(() => {
                    toast.error('Error adding document type');
                });
        }
    };

    const handleRefreshDocumentTypes = () => {
        setIsRefreshing(true);
        refreshDocumentTypes()
            .then(() => {
                setTimeout(() => setIsRefreshing(false), 500); // Убираем класс через 500мс
            })
            .catch(() => {
                setIsRefreshing(false);
                toast.error('Error refreshing document types');
            });
    };

    const handleEditClick = (type) => {
        setEditingType(type);
        setEditingTypeName(type.name);
        setShowModal(true);
    };

    const handleEditSave = () => {
        if (editingType && editingTypeName) {
            editDocumentType(editingType.id, { name: editingTypeName })
                .then(() => {
                    setShowModal(false);
                    setEditingType(null);
                    setEditingTypeName('');
                    handleRefreshDocumentTypes();
                    toast.success('Document type edited successfully');
                })
                .catch(() => {
                    toast.error('Error editing document type');
                });
        }
    };

    const handleDeleteClick = (type) => {
        setDeleteTypeId(type.id);
        setDeleteTypeName(type.name);
        setShowDeleteModal(true);
    };

    const confirmDelete = () => {
        deleteDocumentType(deleteTypeId)
            .then(() => {
                handleRefreshDocumentTypes();
                toast.success('Document type deleted successfully');
                setShowDeleteModal(false);
                setDeleteTypeId(null);
                setDeleteTypeName('');
                setDeleteConfirmed(false);
            })
            .catch(() => {
                toast.error('Error deleting document type');
                setShowDeleteModal(false);
                setDeleteTypeId(null);
                setDeleteTypeName('');
                setDeleteConfirmed(false);
            });
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Container>
            <h2>Document Types</h2>
            <Row>
                <Col>
                    <Form autoComplete="off">
                        <Form.Group className="d-flex align-items-center my-2">
                            <Form.Label className="me-2">New Type</Form.Label>
                            <Form.Control
                                type="text"
                                value={newTypeName}
                                onChange={(e) => setNewTypeName(e.target.value)}
                                placeholder="Enter new document type name"
                                className="me-2"
                            />
                            <Button onClick={handleAddDocumentType}>Add</Button>
                        </Form.Group>
                    </Form>
                </Col>
                <Col className="d-flex align-items-center my-2">
                    <Button onClick={handleRefreshDocumentTypes} variant={"secondary"}><ArrowClockwise /></Button>
                </Col>
            </Row>

            <Table striped bordered hover className={isRefreshing ? 'placeholder-glow' : ''}>
                <thead>
                <tr>
                    <th>Type Name</th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {isRefreshing ? (
                    documentTypes.map(() => (
                            <TablePlaceholder />
                    ))
                ) : (
                    documentTypes && documentTypes.length > 0 ? (
                        documentTypes.map((type) => (
                            <tr key={type.id}>
                                <td>{type.name}</td>
                                <td>
                                    <Button variant="warning" title="Edit" className="me-2" onClick={() => handleEditClick(type)}><Pencil /></Button>
                                    <Button variant="danger" title="Delete" onClick={() => handleDeleteClick(type)}><Trash /></Button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="2">No document types available. Please add a new document type.</td>
                        </tr>
                    )
                )}
                </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Document Type</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Type Name</Form.Label>
                            <Form.Control
                                type="text"
                                value={editingTypeName}
                                onChange={(e) => setEditingTypeName(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleEditSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete the document type "<strong>{deleteTypeName}</strong>"?</p>
                    <Form>
                        <Form.Group>
                            <Form.Check
                                type="checkbox"
                                label="I agree"
                                checked={deleteConfirmed}
                                onChange={(e) => setDeleteConfirmed(e.target.checked)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={confirmDelete} disabled={!deleteConfirmed}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default DocumentTypeManager;
