import {Col, Row} from "react-bootstrap";

// Base module component
const ModB = (props) => {
    return <>
        <Row className={props.className}>
            <Row>
                <Col>
                    <h1>{props.title}</h1>
                </Col>
            </Row>

            {props.children}
        </Row>
    </>;
}

export default ModB;