// src/pagies/Documents/index.jsx

import TreeManager from "../../components/TreeManager";

const DocumentManager = () => {
    return (
        <>
            <h1>Document Manager</h1>
            <TreeManager/>
        </>
    );
};

export default DocumentManager;