// App.js
import React from 'react';
import './App.scss';
import Header from "./components/header/Header";
import { Container } from "react-bootstrap";
import ModRecent from "./components/modules/ModRecent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DocumentTypeManager from "./components/typeManager/DocumentTypeManager";
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from "./components/ProtectedRoute";
import LandingPage from "./components/LandingPage/LandingPage";
import Footer from "./components/Footer/Footer";
import { LogtoProvider } from "@logto/react";
import Callback from "./pagies/Callback";
import oidcConfig from "./services/auth";
import { DocumentProvider } from "./contexts/DocumentContext";
import TokenInterceptorSetup from './TokenInterceptorSetup';
import DocumentManager from "./pagies/Documents";

function App() {
    return (
        <LogtoProvider config={oidcConfig}>
            <DocumentProvider>
                <BrowserRouter>
                    <Header />
                    <ToastContainer position="top-right" autoClose={5000} />
                    <Container>
                        <TokenInterceptorSetup>
                            <Routes>
                                <Route path="/" element={<ProtectedRoute element={ModRecent} />} />
                                <Route path="/landing" element={<LandingPage />} />
                                <Route path="/doc/type" element={<ProtectedRoute element={DocumentTypeManager} />} />
                                <Route path="/log-to-callback" element={<Callback />} />
                                <Route path="/documents" element={<DocumentManager />} />
                            </Routes>
                        </TokenInterceptorSetup>
                    </Container>
                    <Footer />
                </BrowserRouter>
            </DocumentProvider>
        </LogtoProvider>
    );
}

export default App;
