import React from 'react';
import { Navigate } from 'react-router-dom';
import {useLogto} from '@logto/react';

const ProtectedRoute = ({ element: Component }) => {
    const { isAuthenticated } = useLogto();

    return isAuthenticated ? (
        <Component />
    ) : (
        <Navigate to="/landing" />
    );
};

export default ProtectedRoute;
