import { React, useEffect, useState } from 'react';
import { useLogto } from "@logto/react";
import { LoginLogoutButton } from "../LoginLogoutButton";
import { Person } from 'react-bootstrap-icons';
import { Nav } from 'react-bootstrap';

const UserHeader = () => {
    const { isAuthenticated, fetchUserInfo } = useLogto();
    const [userInfo, setUserInfo] = useState();

    useEffect(() => {
        (async () => {
            if (isAuthenticated) {
                const userInfo = await fetchUserInfo();
                setUserInfo(userInfo);
            }
        })();
    }, [fetchUserInfo, isAuthenticated]);

    return (
        <Nav className="ml-auto">
            {isAuthenticated && userInfo ? (
                <Nav.Item className="d-flex align-items-center">
                    <Person size={24} className="mr-2" />
                    <span className="mr-3 p-2">{userInfo.email}</span>
                    <LoginLogoutButton isAuthenticated={isAuthenticated} />
                </Nav.Item>
            ) : (
                <LoginLogoutButton isAuthenticated={isAuthenticated} />
            )}
        </Nav>
    );
}

export default UserHeader;
