import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {LoginLogoutButton} from "../LoginLogoutButton";
import {useLogto} from "@logto/react";

const LandingPage = () => {
    const { isAuthenticated } = useLogto();
    const navigate = useNavigate();
console.log('landing');
    useEffect(() => {
        if (isAuthenticated) {
            console.log('landing effect redirect');
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    return (
        <div className="landing-page">
            <h1>Welcome to HDoc UI</h1>
            <p>Please log in to access the application.</p>
            <LoginLogoutButton isAuthenticated={isAuthenticated} />
        </div>
    );
};

export default LandingPage;
