import React, { useState, useEffect, useCallback } from 'react';
import { apiService } from '../services/apiService';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import { Plus, Pencil, Trash } from "react-bootstrap-icons";
import { Button, Modal, Form } from 'react-bootstrap';

const TreeManager = () => {
    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [newNodeName, setNewNodeName] = useState('');
    const [parentNodeId, setParentNodeId] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);

    // Transform the data for use in TreeTable
    const transformTreeData = useCallback((nodes, isPartial = false) => {
        return nodes.map(node => ({
            key: node.id ? node.id.toString() : Math.random().toString(),
            data: {
                label: node.name,
                icon: node.children && node.children.length > 0 ? 'pi pi-fw pi-folder' : 'pi pi-fw pi-file'
            },
            leaf: node.children.length === 0 && !isPartial, // Mark as leaf only if it's not a partial load
            children: node.children.length > 0 ? transformTreeData(node.children) : (isPartial ? [] : null),
            partial: isPartial // Add partial flag for nodes that might have more children
        }));
    }, []);

    // Fetch root nodes on component mount
    const fetchTreeData = useCallback(async () => {
        try {
            const response = await apiService.getTree();
            const transformedData = transformTreeData(response.data, true); // Mark the root as partial
            setTreeData(transformedData);
        } catch (error) {
            console.error('Failed to fetch tree data:', error);
        } finally {
            setLoading(false);
        }
    }, [transformTreeData]);

    useEffect(() => {
        fetchTreeData();
    }, [fetchTreeData]);

    const handleAddNode = async () => {
        if (!newNodeName) {
            alert('Please enter a name for the new node');
            return;
        }

        const newNode = {
            Name: newNodeName,
            parent_id: parentNodeId || null, // Set ParentId to null for root nodes
        };

        try {
            await apiService.addTreeNode(newNode);
            setNewNodeName('');
            setParentNodeId(null);
            setShowAddModal(false);
            fetchTreeData(); // Refresh tree after adding new node
        } catch (error) {
            console.error('Failed to add new node:', error);
        }
    };

    const onExpand = async (event) => {
        const node = event.node;

        if (!node.children || node.partial) {
            setLoading(true);
            try {
                const response = await apiService.getTreeNode(node.key);
                const transformedData = transformTreeData(response.data.children, true);

                const updateNodeChildren = (nodes, nodeId, children) => {
                    return nodes.map((n) => {
                        if (n.key === nodeId) {
                            return { ...n, children: children, leaf: false, partial: false }; // Update node and remove partial flag
                        } else if (n.children) {
                            return { ...n, children: updateNodeChildren(n.children, nodeId, children) };
                        }
                        return n;
                    });
                };

                const updatedTreeData = updateNodeChildren(treeData, node.key, transformedData);
                setTreeData(updatedTreeData);
            } catch (error) {
                console.error('Failed to load children nodes:', error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleShowAddModal = (parentId = null) => {
        setParentNodeId(parentId);
        setShowAddModal(true);
    };

    const actionTemplate = (rowData) => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button variant="success" title="Add" onClick={() => handleShowAddModal(rowData.key)}><Plus /></Button>
                <Button variant="warning" title="Edit" ><Pencil /></Button>
                <Button variant="danger" title="Delete" ><Trash /></Button>
            </div>
        );
    };

    if (loading && treeData.length === 0) {
        return <div>Loading tree data...</div>;
    }

    return (
        <>
            <div style={{ marginTop: '20px' }}>
                <h3>Add New Root Node</h3>
                <input
                    type="text"
                    placeholder="Node Name"
                    value={newNodeName}
                    onChange={(e) => setNewNodeName(e.target.value)}
                />
                <Button variant="primary" onClick={() => handleShowAddModal(null)}>Add Root Node</Button>
            </div>

            <TreeTable value={treeData} lazy onExpand={onExpand} loading={loading} tableStyle={{ minWidth: '50rem' }}>
                <Column field="label" header="Name" expander></Column>
                <Column body={actionTemplate} headerClassName="w-10rem" />
            </TreeTable>

            <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Node</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Node Name</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter node name"
                            value={newNodeName}
                            onChange={(e) => setNewNodeName(e.target.value)}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleAddNode}>
                        Add Node
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default TreeManager;
