import React from 'react';
import Button from "react-bootstrap/Button";
import { useLogto } from "@logto/react";

export const LoginLogoutButton = ({ isAuthenticated }) => {
    const { signIn, signOut } = useLogto();
    const currentUrl = window.location.origin;

    return (
        <>
            {isAuthenticated ? (
                <Button onClick={() => signOut(`${currentUrl}/landing`)} variant="outline-primary">Logout</Button>
            ) : (
                <Button onClick={() => signIn(`${currentUrl}/log-to-callback`)} variant="outline-success">Login</Button>
            )}
        </>
    );
};
