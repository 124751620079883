import {FileEarmarkText} from "react-bootstrap-icons";
import moment from "moment";
import './document.scss';

const Document = (props) => {
    return <div className='document'>
        <div className='img'>
            <FileEarmarkText size={70}/>
        </div>
        <div className='details'>
            <h6>{props.title}</h6>
            <span>{props.path}</span>
            <span>{moment(props.expDate).format('DD/MM/YY')}</span>
        </div>
    </div>
}

export default Document;